<template>
  <div>

    <vx-card title="BANK KBANK">

      <vs-table stripe :data="bankscb">

        <template slot="thead">
          <vs-th>Id</vs-th>
          <vs-th>For</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Bank Number</vs-th>
          <!-- <vs-th>Username</vs-th> -->
          <!-- <vs-th>Password</vs-th> -->
          <vs-th>Type</vs-th>
          <vs-th></vs-th>
          <vs-th>แสดงหน้าเว็บ</vs-th>
          <vs-th>ดึงยอดธนาคาร</vs-th>
          <vs-th>ดึงยอดsms</vs-th>
          <vs-th>แอดเครดิต</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.bank_id">
              {{tr.bank_id}}
            </vs-td>
            <vs-td :data="tr.bank_for">
              {{tr.bank_for}}
            </vs-td>
            <vs-td :data="tr.bank_fullname">
              {{tr.bank_fullname}}
            </vs-td>
            <vs-td :data="tr.bank_number">
              {{tr.bank_number}}
            </vs-td>
            <!-- <vs-td :data="tr.bank_username">
              {{tr.bank_username}}

            </vs-td>
              <vs-td :data="tr.bank_password">
              {{tr.bank_password}}
            </vs-td> -->
                    <vs-td  v-if="tr.bank_reserve == 0" class="text-success">
              บัญชีหลัก
            </vs-td>
            <vs-td  v-if="tr.bank_reserve == 1" class="text-danger">
              บัญชีสำรอง
            </vs-td>
            <div class="flex">
              <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" color="warning" class="mr-2"
                @click="$router.push('/SettingWalletDeposit/SettingWalletedit/'+bank+'/'+tr.bank_id)">แก้ไขบัญชีฝาก
                </vs-button>
                <vs-button v-if="admin_user.username === 'pgmsup' || admin_user.username === 'pgmkub'" type="border" size="small" icon-pack="feather" icon="icon-trash" class="mr-2"  @click="popup_delbank(tr.bank_id,tr.bank_number,tr.bank_fullname)">ลบบัญชีฝาก</vs-button>

                 <!-- <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" class="mr-2"  @click="popup_delbank(tr.bank_id,tr.bank_number,tr.bank_fullname)">ลบบัญชีฝาก</vs-button> -->
            </div>
             <vs-td :data="tr.bank_show">
              <vs-switch v-if="tr.bank_reserve == 0" :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" v-model="tr.bank_show" @click="update_bankshow(tr.bank_id,tr.bank_fullname,tr.bank_show)">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </vs-td>
            <vs-td :data="tr.bank_run">
              <vs-switch  v-model="tr.bank_run" :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"  @click="update_bankrun(tr.bank_id,tr.bank_fullname,tr.bank_run)">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </vs-td>
            <vs-td :data="tr.bank_run_sms">
              <vs-switch v-model="tr.bank_run_sms" :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"  @click="update_bankrun_sms(tr.bank_id,tr.bank_fullname,tr.bank_run_sms)">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </vs-td>
            <vs-td :data="tr.bank_run_addcredit">
              <vs-switch v-model="tr.bank_run_addcredit" :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"  @click="update_bankadd_app(tr.bank_id,tr.bank_fullname,tr.bank_run_addcredit)">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </vs-td>
          </vs-tr>

        </template>

      </vs-table>

      <vs-popup classContent="popup-example" title="ลบยูสเซอร์บัญชีฝาก" :active.sync="popup_del_bank">
      <h4> คุณกำลังจะลบเลขบัญชี: <b style="color:#F61340">{{delete_bankno}}</b></h4> <small >กรุณายืนยันตัวตน โปรดยืนยัน <b> Password</b> ของคุณ</small>
      <br>
      <vx-input-group class="mb-base">
        <template slot="prepend">
          <div class="prepend-text bg-primary">
            <span>Username</span>
          </div>
        </template>
        <vs-input placeholder="Username" v-model="admin_user.username" disabled />
      </vx-input-group>

      <vx-input-group class="mb-base">
        <template slot="prepend">
          <div class="prepend-text bg-primary">
            <span>Password</span>
          </div>
        </template>

        <vs-input ref="inputPassword" placeholder="Password" v-model="admin_pass" />
      </vx-input-group>
      <vs-button @click="deletedata()" color="primary" type="filled">ยืนยัน</vs-button>
    </vs-popup>
    </vx-card>
  </div>
</template>

<script>
import axios from '../../../../axios'

export default {
  data () {
    return {
      bankscb: [],
      bank:'bank_kbank_only',
      status: false,
      delete_id: '',
      delete_bankno:'',
      admin_user: this.$store.state.AppActiveUser,
      admin_pass:'',
      popup_del_bank: false
    }
  },
  async mounted () {
    await axios
      .get('bank/bank/kbankonly')
      .then(response => (this.bankscb = response.data))
  },
  methods: {
    async update_bankshow (bank_id, bank_fullname, bank_show) {
      if (bank_show === 1 || bank_show === true) {
        bank_show = 0
      } else {
        bank_show = 1
      }
      await axios
        .post('bank/updatebankshow', {
          bank: 'kbankonly',
          id: bank_id,
          show: bank_show
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_show === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_show === 1 ? 'check_box' : 'error',
          title: bank_show === 1 ? 'เปิดแสดง kbankonly หน้าเว็บสำเร็จ' : 'ปิดแสดง kbankonly หน้าเว็บสำเร็จ',
          text: bank_show === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })


      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_show === 1 ? 'เปิดแสดง kbankonly หน้าเว็บไม่สำเร็จ!!' : 'ปิดแสดง kbankonly หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_bankrun (bank_id, bank_fullname, bank_run) {
      if (bank_run === 1 || bank_run === true) {
        bank_run = 0
      } else {
        bank_run = 1
      }
      await axios
        .post('bank/bank_all/run', {
          bankid:bank_id,
          bankrun:bank_run,
          bank:'kbankonly'
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_run === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_run === 1 ? 'check_box' : 'error',
          title: bank_run === 1 ? 'เปิดดึงยอด kbankonly สำเร็จ' : 'ปิดดึงยอด kbankonly สำเร็จ',
          text: bank_run === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })


      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_run === 1 ? 'เปิดดึงยอด kbankonly หน้าเว็บไม่สำเร็จ!!' : 'ปิดดึงยอด kbankonly หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_bankadd_app (bank_id, bank_fullname, bank_run_addcredit) {
      if (bank_run_addcredit === 1 || bank_run_addcredit === true) {
        bank_run_addcredit = 0
      } else {
        bank_run_addcredit = 1
      }
      await axios
        .post('bank/bank_all/run_add', {
          bankid:bank_id,
          bankrun:bank_run_addcredit,
          bank:'kbankonly'
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_run_addcredit === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_run_addcredit === 1 ? 'check_box' : 'error',
          title: bank_run_addcredit === 1 ? 'เปิดแอดเครดิต kbankonly สำเร็จ' : 'ปิดแอดเครดิต kbankonly สำเร็จ',
          text: bank_run_addcredit === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })


      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_run_addcredit === 1 ? 'เปิดดึงยอด kbankonly หน้าเว็บไม่สำเร็จ!!' : 'ปิดดึงยอด kbankonly หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_bankrun_sms (bank_id, bank_fullname, bank_run_sms) {
      if (bank_run_sms === 1 || bank_run_sms === true) {
        bank_run_sms = 0
      } else {
        bank_run_sms = 1
      }
      await axios
        .post('bank/bank_all/run_sms', {
          bankid:bank_id,
          bankrun:bank_run_sms,
          bank:'kbankonly'
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_run_sms === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_run_sms === 1 ? 'check_box' : 'error',
          title: bank_run_sms === 1 ? 'เปิดดึงยอดsms kbankonly สำเร็จ' : 'ปิดดึงยอดsms kbankonly สำเร็จ',
          text: bank_run_sms === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })


      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_run_sms === 1 ? 'เปิดดึงยอดsms kbankonly ไม่สำเร็จ!!' : 'ปิดดึงยอดsms kbankonly ไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async popup_delbank (id, user, name) {
      this.delete_id = id
      this.delete_bankno = user
      this.delete_name = name
      this.popup_del_bank = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    },
    async deletedata () {
      await axios
        .post('settingwallet/deletebankdeposit', {
          admin_user: this.admin_user.username,
          admin_pass: this.admin_pass,
          bankid: this.delete_id,
          bank: this.bank
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })

        setTimeout(() => { window.location.reload() }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ลบข้อมูลไม่สำเร็จ',
          text: this.status.info
        })
      }
    }

  }
}

</script>
